import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Loader from '../../admin/modal-overlays/loader';

const HeroSection = () => {
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const submitEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    addToMailchimp(email)
      .then((data) => {
        if (data.result == 'error') {
          if (data.msg.search('is an invalid email address') >= 0) {
            setMsg('This email address is invalid');
          } else if (data.msg.search('is already subscribed') >= 0) {
            setMsg('This email address is already subscribed');
          } else {
            setMsg('An error occured while subscribing');
          }
          setLoading(false);
        } else {
          setEmail('');
          setMsg('Successfully Subscribed!');
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <section className="banner-section">
      <div className="banner-section-content">
        <div className="banner-text-wrap">
          <h1 className="banner-text-h1">Daily Market Timing</h1>
          <p className="banner-text-p">Get the edge</p>
        </div>
        <form className="report-form">
          <input
            type="email"
            id="email"
            placeholder="Type your email"
            className="report-form-input"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" className="report-form-button" onClick={submitEmail}>
            Get the Reports
          </button>
        </form>
      </div>
      <p className="mt-3 text-center hero-msg">{msg}</p>
      <Loader isOpen={loading} />
    </section>
  );
};

export default HeroSection;
